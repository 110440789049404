// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");

var button = Curry._1(Css.style, {
      hd: Css.fontFamily({
            NAME: "custom",
            VAL: "\"Helvetica Neue\", Helvetica, Arial, sans-serif"
          }),
      tl: {
        hd: Css.fontWeight("bold"),
        tl: {
          hd: Css.color({
                NAME: "hex",
                VAL: "fff"
              }),
          tl: {
            hd: Css.fontSize({
                  NAME: "px",
                  VAL: 17
                }),
            tl: {
              hd: Css.background({
                    NAME: "hex",
                    VAL: "1A8FE6"
                  }),
              tl: {
                hd: Css.padding({
                      NAME: "px",
                      VAL: 20
                    }),
                tl: {
                  hd: Css.margin2({
                        NAME: "px",
                        VAL: 10
                      }, {
                        NAME: "px",
                        VAL: 0
                      }),
                  tl: {
                    hd: Css.borderRadius({
                          NAME: "px",
                          VAL: 5
                        }),
                    tl: {
                      hd: Css.cursor("pointer"),
                      tl: {
                        hd: Css.width({
                              NAME: "px",
                              VAL: 282
                            }),
                        tl: {
                          hd: Css.selector("&:disabled", {
                                hd: Css.opacity(0.5),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var textInput = Curry._1(Css.style, {
      hd: Css.padding({
            NAME: "px",
            VAL: 20
          }),
      tl: {
        hd: Css.fontSize({
              NAME: "px",
              VAL: 17
            }),
        tl: {
          hd: Css.border({
                NAME: "px",
                VAL: 1
              }, "solid", {
                NAME: "hex",
                VAL: "ccc"
              }),
          tl: {
            hd: Css.width({
                  NAME: "px",
                  VAL: 240
                }),
            tl: {
              hd: Css.margin2({
                    NAME: "px",
                    VAL: 10
                  }, {
                    NAME: "px",
                    VAL: 0
                  }),
              tl: {
                hd: Css.color({
                      NAME: "hex",
                      VAL: "494949"
                    }),
                tl: {
                  hd: Css.borderRadius({
                        NAME: "px",
                        VAL: 5
                      }),
                  tl: {
                    hd: Css.selector("&::placeholder", {
                          hd: Css.color({
                                NAME: "hex",
                                VAL: "ccc"
                              }),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

exports.button = button;
exports.textInput = textInput;
/* button Not a pure module */
