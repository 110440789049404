// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Link = require("next/link").default;
var ReactUtils$SmartcutsMacros = require("../../packages/bs-utils/src/ReactUtils.bs.js");

var styles = {
  marginRight: "10px"
};

function Header(Props) {
  var home = Props.home;
  var onClickCallToAction = Props.onClickCallToAction;
  return React.createElement("header", {
              className: Curry._1(Css.style, {
                    hd: Css.width({
                          NAME: "percent",
                          VAL: 100
                        }),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.height(Css.px(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.width({
                                    NAME: "percent",
                                    VAL: 100
                                  }),
                              tl: {
                                hd: Css.maxWidth(Css.px(1140)),
                                tl: {
                                  hd: Css.marginLeft("auto"),
                                  tl: {
                                    hd: Css.marginRight("auto"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("h1", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontFamily({
                                  NAME: "custom",
                                  VAL: "\"Helvetica Neue\", Helvetica, Arial, sans-serif"
                                }),
                            tl: {
                              hd: Css.margin("zero"),
                              tl: {
                                hd: Css.color({
                                      NAME: "hex",
                                      VAL: "1A8FE6"
                                    }),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, home ? React.createElement(React.Fragment, undefined, React.createElement("img", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width(Css.px(40)),
                                      tl: {
                                        hd: Css.verticalAlign("middle"),
                                        tl: {
                                          hd: Css.marginRight({
                                                NAME: "px",
                                                VAL: 10
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }),
                                src: "/img/logo66.png"
                              }), React.createElement("span", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontSize(Css.px(30)),
                                      tl: {
                                        hd: Css.fontWeight({
                                              NAME: "num",
                                              VAL: 400
                                            }),
                                        tl: {
                                          hd: Css.media("(max-width: 414px)", {
                                                hd: Css.fontSize(Css.px(20)),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, ReactUtils$SmartcutsMacros.s("smartcuts"))) : React.createElement(Link, {
                            href: "/",
                            children: React.createElement("a", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.textDecoration("none"),
                                        tl: {
                                          hd: Css.color({
                                                NAME: "hex",
                                                VAL: "1A8FE6"
                                              }),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement("img", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.verticalAlign("middle"),
                                            tl: {
                                              hd: Css.marginRight({
                                                    NAME: "px",
                                                    VAL: 20
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      src: "/img/logo66.png"
                                    }), React.createElement("span", undefined, ReactUtils$SmartcutsMacros.s("smartcuts")))
                          })), React.createElement("nav", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("flexEnd"),
                                  tl: {
                                    hd: Css.flexGrow(1),
                                    tl: {
                                      hd: Css.flexShrink(1),
                                      tl: {
                                        hd: Css.flexBasis({
                                              NAME: "percent",
                                              VAL: 1
                                            }),
                                        tl: {
                                          hd: Css.media("(max-width: 414px)", {
                                                hd: Css.display("none"),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement(Link, {
                          href: "/login",
                          children: React.createElement("a", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontFamily({
                                            NAME: "custom",
                                            VAL: "\"Helvetica Neue\", Helvetica, Arial, sans-serif"
                                          }),
                                      tl: {
                                        hd: Css.color({
                                              NAME: "hex",
                                              VAL: "1A8FE6"
                                            }),
                                        tl: {
                                          hd: Css.textDecoration("none"),
                                          tl: {
                                            hd: Css.fontSize({
                                                  NAME: "px",
                                                  VAL: 20
                                                }),
                                            tl: {
                                              hd: Css.marginRight(Css.px(24)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, ReactUtils$SmartcutsMacros.s("Login"))
                        })), React.createElement("button", {
                      className: Curry._1(Css.style, {
                            hd: Css.backgroundColor({
                                  NAME: "hex",
                                  VAL: "fd6f2f"
                                }),
                            tl: {
                              hd: Css.color({
                                    NAME: "hex",
                                    VAL: "fff"
                                  }),
                              tl: {
                                hd: Css.borderRadius(Css.px(3)),
                                tl: {
                                  hd: Css.padding2(Css.px(8), Css.px(20)),
                                  tl: {
                                    hd: Css.fontWeight({
                                          NAME: "num",
                                          VAL: 600
                                        }),
                                    tl: {
                                      hd: Css.textTransform("uppercase"),
                                      tl: {
                                        hd: Css.cursor("pointer"),
                                        tl: {
                                          hd: Css.borderStyle("none"),
                                          tl: {
                                            hd: Css.height(Css.px(40)),
                                            tl: {
                                              hd: Css.media("(max-width: 414px)", {
                                                    hd: Css.fontSize(Css.px(12)),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      onClick: (function (_event) {
                          return Curry._1(onClickCallToAction, undefined);
                        })
                    }, ReactUtils$SmartcutsMacros.s("Request "), React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.media("(max-width: 414px)", {
                                      hd: Css.display("none"),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              })
                        }, ReactUtils$SmartcutsMacros.s("Early ")), ReactUtils$SmartcutsMacros.s("Access"))));
}

var make = Header;

exports.styles = styles;
exports.make = make;
/* Css Not a pure module */
