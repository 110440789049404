// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var CssJs = require("bs-css-emotion/src/CssJs.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_math = require("rescript/lib/js/js_math.js");
var Head = require("next/head").default;
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Js_Core = require("bs-css/src/Css_Js_Core.bs.js");
var Script = require("next/script").default;
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var ReactUtils$SmartcutsMacros = require("../packages/bs-utils/src/ReactUtils.bs.js");
var StylePublic$SmartcutsMacros = require("../src/common/StylePublic.bs.js");
var LayoutPublic$SmartcutsMacros = require("../src/components/LayoutPublic.bs.js");
var YouTubeEmbed$SmartcutsMacros = require("../src/components/YouTubeEmbed.bs.js");

var buttonAction = CssJs.style([
      CssJs.cursor("pointer"),
      CssJs.color(CssJs.hex("fff")),
      CssJs.fontSize({
            NAME: "px",
            VAL: 17
          }),
      CssJs.fontWeight("bold"),
      CssJs.background(CssJs.hex("fd6f2f")),
      CssJs.padding({
            NAME: "px",
            VAL: 17
          }),
      CssJs.padding2({
            NAME: "px",
            VAL: 17
          }, {
            NAME: "px",
            VAL: 20
          }),
      CssJs.border({
            NAME: "px",
            VAL: 4
          }, "solid", CssJs.hex("fd6f2f")),
      CssJs.borderRadius({
            NAME: "px",
            VAL: 4
          }),
      CssJs.transition(400, 0, "ease", "all"),
      CssJs.boxShadow(Css_Js_Core.Shadow.box("zero", CssJs.px(6), CssJs.px(9), CssJs.px(2), undefined, CssJs.rgba(82, 210, 188, {
                    NAME: "percent",
                    VAL: 30
                  }))),
      CssJs.selector("&:hover", [
            CssJs.transition(400, 0, "ease", "all"),
            CssJs.boxShadow(Css_Js_Core.Shadow.box("zero", CssJs.px(6), CssJs.px(25), CssJs.px(3), undefined, CssJs.rgba(0, 0, 0, {
                          NAME: "percent",
                          VAL: 15
                        })))
          ])
    ]);

var success = CssJs.style([
      CssJs.fontSize({
            NAME: "px",
            VAL: 21
          }),
      CssJs.color(CssJs.hex("1d8fe5")),
      CssJs.lineHeight({
            NAME: "px",
            VAL: Js_math.ceil(21.0 * 1.5)
          }),
      CssJs.textAlign("center")
    ]);

var error = CssJs.style([
      CssJs.fontSize({
            NAME: "px",
            VAL: 21
          }),
      CssJs.color(CssJs.hex("ed7575")),
      CssJs.lineHeight({
            NAME: "px",
            VAL: Js_math.ceil(21.0 * 1.5)
          })
    ]);

var supportedAppsList = CssJs.style([
      CssJs.maxWidth(CssJs.px(650)),
      CssJs.listStyleType("none"),
      CssJs.padding("zero"),
      CssJs.display("flex"),
      CssJs.flexDirection("row"),
      CssJs.flexWrap("wrap"),
      CssJs.justifyContent("center"),
      CssJs.alignItems("flexStart")
    ]);

var supportedApp = CssJs.style([
      CssJs.display("inlineBlock"),
      CssJs.width(CssJs.px(50)),
      CssJs.height(CssJs.px(50)),
      CssJs.boxShadow(Css_Js_Core.Shadow.box(undefined, undefined, CssJs.px(1), CssJs.px(2), undefined, CssJs.rgba(0, 0, 0, {
                    NAME: "num",
                    VAL: 0.15
                  }))),
      CssJs.padding(CssJs.px(2)),
      CssJs.boxSizing("borderBox"),
      CssJs.borderRadius(CssJs.px(5)),
      CssJs.margin(CssJs.px(5)),
      CssJs.display("flex"),
      CssJs.alignItems("center"),
      CssJs.justifyContent("center")
    ]);

var Style = {
  buttonAction: buttonAction,
  success: success,
  error: error,
  supportedAppsList: supportedAppsList,
  supportedApp: supportedApp
};

function Index$SignupForm(Props) {
  var error$1 = Props.error;
  var requestAccess = Props.requestAccess;
  var setState = Props.setState;
  var tmp;
  tmp = error$1 !== undefined ? (
      error$1 === "" ? React.createElement("div", {
              className: error
            }, ReactUtils$SmartcutsMacros.s("There was a mistake in requesting access")) : React.createElement("div", {
              className: error
            }, ReactUtils$SmartcutsMacros.s(error$1))
    ) : React.createElement(React.Fragment, undefined);
  return React.createElement("form", {
              className: CssJs.style([CssJs.media("(max-width: 1224px)", [CssJs.textAlign("center")])]),
              onSubmit: requestAccess
            }, React.createElement("input", {
                  className: "wait-list-email " + (StylePublic$SmartcutsMacros.textInput + (" " + Curry._1(Css.style, {
                            hd: Css.marginRight({
                                  NAME: "px",
                                  VAL: 20
                                }),
                            tl: /* [] */0
                          }))),
                  placeholder: "Add your email to the wait list",
                  type: "text",
                  onChange: (function (e) {
                      var updatedEmail = e.target.value;
                      return Curry._1(setState, (function (_state) {
                                    return {
                                            TAG: /* RequestingAccess */0,
                                            _0: updatedEmail
                                          };
                                  }));
                    })
                }), React.createElement("input", {
                  className: buttonAction,
                  type: "submit",
                  value: "Request Early Access"
                }), tmp);
}

var SignupForm = {
  make: Index$SignupForm
};

function Index$Feature(Props) {
  var header = Props.header;
  var paragraph = Props.paragraph;
  return React.createElement("section", {
              className: CssJs.style([
                    CssJs.display("flex"),
                    CssJs.flexDirection("column"),
                    CssJs.justifyContent("flexStart"),
                    CssJs.maxWidth(CssJs.px(1154)),
                    CssJs.width({
                          NAME: "percent",
                          VAL: 100
                        }),
                    CssJs.margin2(CssJs.px(48), "zero"),
                    CssJs.textAlign("left"),
                    CssJs.media("(max-width: 1224px)", [CssJs.margin2(CssJs.px(12), "zero")])
                  ])
            }, React.createElement("h2", {
                  className: Curry._1(Css.style, {
                        hd: Css.color(Css.hex("1A8FE6")),
                        tl: {
                          hd: Css.fontSize({
                                NAME: "px",
                                VAL: 42
                              }),
                          tl: {
                            hd: Css.lineHeight({
                                  NAME: "px",
                                  VAL: Js_math.ceil(42.0 * 1.5)
                                }),
                            tl: {
                              hd: Css.fontWeight({
                                    NAME: "num",
                                    VAL: 300
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, header), React.createElement("p", {
                  className: Curry._1(Css.style, {
                        hd: Css.color(Css.rgb(153, 153, 153)),
                        tl: {
                          hd: Css.fontSize({
                                NAME: "px",
                                VAL: 16
                              }),
                          tl: {
                            hd: Css.lineHeight({
                                  NAME: "px",
                                  VAL: Js_math.ceil(21.0 * 1.5)
                                }),
                            tl: {
                              hd: Css.maxWidth(Css.px(600)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, paragraph));
}

var Feature = {
  make: Index$Feature
};

function Index$Hero(Props) {
  var match = React.useState(function () {
        return {
                didClickVideo: false
              };
      });
  var setState = match[1];
  return React.createElement("div", {
              className: CssJs.style([
                    CssJs.display("flex"),
                    CssJs.flexDirection("row"),
                    CssJs.width({
                          NAME: "percent",
                          VAL: 100
                        }),
                    CssJs.maxWidth(CssJs.px(1154)),
                    CssJs.margin2(CssJs.px(48), "zero"),
                    CssJs.textAlign("left"),
                    CssJs.media("(max-width: 1224px)", [
                          CssJs.flexDirection("column"),
                          CssJs.margin2(CssJs.px(12), "zero")
                        ])
                  ])
            }, React.createElement("div", {
                  className: CssJs.style([
                        CssJs.display("flex"),
                        CssJs.flexDirection("column"),
                        CssJs.flexGrow(1),
                        CssJs.flexShrink(1),
                        CssJs.flexBasis({
                              NAME: "percent",
                              VAL: 0
                            }),
                        CssJs.alignItems("flexStart"),
                        CssJs.media("(max-width: 1224px)", [
                              CssJs.alignItems("center"),
                              CssJs.padding2("zero", CssJs.px(20))
                            ])
                      ])
                }, React.createElement("h1", {
                      className: CssJs.style([
                            CssJs.color(CssJs.hex("1A8FE6")),
                            CssJs.fontSize({
                                  NAME: "px",
                                  VAL: 48
                                }),
                            CssJs.fontWeight("light"),
                            CssJs.media("(max-width: 1224px)", [
                                  CssJs.textAlign("center"),
                                  CssJs.fontSize(CssJs.px(36)),
                                  CssJs.marginTop(CssJs.px(12)),
                                  CssJs.marginLeft("zero"),
                                  CssJs.marginRight("zero"),
                                  CssJs.maxWidth(CssJs.px(500))
                                ])
                          ])
                    }, ReactUtils$SmartcutsMacros.s("Automate repetitive work"), React.createElement("br", undefined), ReactUtils$SmartcutsMacros.s("on any website"), React.createElement("br", undefined), ReactUtils$SmartcutsMacros.s("in minutes. Without code.")), React.createElement("p", {
                      className: CssJs.style([
                            CssJs.fontSize({
                                  NAME: "px",
                                  VAL: 21
                                }),
                            CssJs.color(CssJs.rgb(153, 153, 153)),
                            CssJs.lineHeight({
                                  NAME: "px",
                                  VAL: Js_math.ceil(21.0 * 1.5)
                                }),
                            CssJs.media("(max-width: 1224px)", [
                                  CssJs.textAlign("center"),
                                  CssJs.fontSize(CssJs.px(21)),
                                  CssJs.maxWidth(CssJs.px(400))
                                ])
                          ])
                    }, ReactUtils$SmartcutsMacros.s("As you click and type, our Chrome extension watches your actions\n           and turns them into automations that you can replay later."))), React.createElement("div", {
                  className: CssJs.style([
                        CssJs.display("flex"),
                        CssJs.alignItems("center"),
                        CssJs.justifyContent("flexEnd"),
                        CssJs.flexGrow(1),
                        CssJs.flexShrink(1),
                        CssJs.flexBasis({
                              NAME: "percent",
                              VAL: 0
                            }),
                        CssJs.padding4(CssJs.px(4), "zero", CssJs.px(4), CssJs.px(20)),
                        CssJs.media("(max-width: 1224px)", [
                              CssJs.justifyContent("center"),
                              CssJs.padding(CssJs.px(16))
                            ])
                      ])
                }, React.createElement("div", {
                      className: CssJs.style([
                            CssJs.display("flex"),
                            CssJs.justifyContent("center"),
                            CssJs.alignItems("center"),
                            CssJs.position("relative"),
                            CssJs.borderRadius(CssJs.px(6)),
                            CssJs.width({
                                  NAME: "percent",
                                  VAL: 100
                                }),
                            CssJs.minHeight(CssJs.px(295)),
                            CssJs.boxShadow(Css_Js_Core.Shadow.box("zero", CssJs.px(4), CssJs.px(12), CssJs.px(0), undefined, CssJs.rgba(29, 36, 86, {
                                          NAME: "percent",
                                          VAL: 30
                                        }))),
                            CssJs.media("(max-width: 1224px)", [CssJs.minHeight(CssJs.px(513))])
                          ])
                    }, match[0].didClickVideo ? React.createElement(YouTubeEmbed$SmartcutsMacros.make, {
                            id: "4po0DAscLFQ",
                            autoplay: true
                          }) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.position("absolute"),
                                      tl: {
                                        hd: Css.width({
                                              NAME: "percent",
                                              VAL: 100
                                            }),
                                        tl: {
                                          hd: Css.height({
                                                NAME: "percent",
                                                VAL: 100
                                              }),
                                          tl: {
                                            hd: Css.zIndex(4),
                                            tl: {
                                              hd: Css.cursor("pointer"),
                                              tl: {
                                                hd: Css.background(Css.rgba(70, 78, 93, {
                                                          NAME: "num",
                                                          VAL: 0.5
                                                        })),
                                                tl: {
                                                  hd: Css.transition(400, 0, "ease", "background"),
                                                  tl: {
                                                    hd: Css.borderRadius(Css.px(6)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                onClick: (function (param) {
                                    return Curry._1(setState, (function (param) {
                                                  return {
                                                          didClickVideo: true
                                                        };
                                                }));
                                  })
                              }), React.createElement("img", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width({
                                            NAME: "percent",
                                            VAL: 100
                                          }),
                                      tl: /* [] */0
                                    }),
                                src: "/img/promo-thumbnail-frame.png"
                              }), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.position("absolute"),
                                        tl: {
                                          hd: Css.zIndex(8),
                                          tl: {
                                            hd: Css.width(Css.px(96)),
                                            tl: {
                                              hd: Css.height(Css.px(96)),
                                              tl: {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: {
                                                    hd: Css.justifyContent("center"),
                                                    tl: {
                                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box("zero", {
                                                                NAME: "pxFloat",
                                                                VAL: 0.664142
                                                              }, {
                                                                NAME: "pxFloat",
                                                                VAL: 0.996212
                                                              }, undefined, undefined, Css.rgba(0, 0, 0, {
                                                                    NAME: "percent",
                                                                    VAL: 2
                                                                  }))),
                                                      tl: {
                                                        hd: Css.backgroundColor(Css.rgba(70, 78, 93, {
                                                                  NAME: "num",
                                                                  VAL: 0.8
                                                                })),
                                                        tl: {
                                                          hd: Css.borderRadius({
                                                                NAME: "percent",
                                                                VAL: 50
                                                              }),
                                                          tl: {
                                                            hd: Css.transition(400, 0, "ease", "transform"),
                                                            tl: {
                                                              hd: Css.borderWidth(Css.px(6)),
                                                              tl: {
                                                                hd: Css.borderStyle("solid"),
                                                                tl: {
                                                                  hd: Css.borderColor(Css.hex("fff")),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                onClick: (function (param) {
                                    return Curry._1(setState, (function (param) {
                                                  return {
                                                          didClickVideo: true
                                                        };
                                                }));
                                  })
                              }, React.createElement("svg", {
                                    height: "28",
                                    width: "28",
                                    fill: "white",
                                    viewBox: "0 0 32 32",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }, React.createElement("path", {
                                        d: "M7.74,31.44C5.67,32.69,4,31.74,4,29.32V2.67C4,.25,5.68-.7,7.74.55L29.56,13.73c2.07,1.25,2.06,3.28,0,4.52Z"
                                      })))))));
}

var Hero = {
  make: Index$Hero
};

function Index$default(Props) {
  var match = React.useState(function () {
        return /* Init */0;
      });
  var setState = match[1];
  var state = match[0];
  var requestAccess = function (e) {
    e.preventDefault();
    if (typeof state === "number") {
      return ;
    }
    if (state.TAG !== /* RequestingAccess */0) {
      return ;
    }
    fetch("/api/request_access", Fetch.RequestInit.make(/* Post */2, {
                  "Content-Type": "application/json"
                }, Caml_option.some(JSON.stringify(Js_dict.fromList({
                              hd: [
                                "email",
                                state._0
                              ],
                              tl: /* [] */0
                            }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (res) {
          var _status = res.status;
          if (_status !== 200) {
            return res.text().then(function (errMsg) {
                        Curry._1(setState, (function (_state) {
                                return {
                                        TAG: /* SignupError */1,
                                        _0: errMsg
                                      };
                              }));
                        return Promise.resolve(undefined);
                      });
          } else {
            Curry._1(setState, (function (_state) {
                    return /* SignedUp */1;
                  }));
            return Promise.resolve(undefined);
          }
        });
    
  };
  var tmp;
  var exit = 0;
  if (typeof state === "number") {
    exit = state === /* Init */0 ? 1 : 2;
  } else if (state.TAG === /* RequestingAccess */0) {
    exit = 1;
  } else {
    var error = state._0;
    if (error === "instance not unique") {
      exit = 2;
    } else {
      tmp = React.createElement(Index$SignupForm, {
            error: error,
            requestAccess: requestAccess,
            setState: setState
          });
    }
  }
  switch (exit) {
    case 1 :
        tmp = React.createElement(Index$SignupForm, {
              requestAccess: requestAccess,
              setState: setState
            });
        break;
    case 2 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: success
                }, ReactUtils$SmartcutsMacros.s("You've been added to the wait list. "), ReactUtils$SmartcutsMacros.s("Fill out this "), React.createElement("button", {
                      className: buttonAction,
                      onClick: (function (_event) {
                          window.open("https://forms.gle/v19t5XkSAoEdroVr5", "_blank", undefined);
                          
                        })
                    }, ReactUtils$SmartcutsMacros.s("form")), ReactUtils$SmartcutsMacros.s(" to get access sooner.")));
        break;
    
  }
  return React.createElement(LayoutPublic$SmartcutsMacros.make, {
              home: true,
              onClickCallToAction: (function (param) {
                  var element = document.querySelector("input.wait-list-email");
                  if (!(element == null)) {
                    element.focus();
                    element.scrollIntoView({
                          behavior: "smooth",
                          block: "center"
                        });
                    return ;
                  }
                  
                }),
              children: null
            }, React.createElement(Script, {
                  id: "drift",
                  dangerouslySetInnerHTML: {
                    __html: "!function() {\n    var t = window.driftt = window.drift = window.driftt || [];\n    if (!t.init) {\n      if (t.invoked) return void (window.console && console.error && console.error(\"Drift snippet included twice.\"));\n      t.invoked = !0, t.methods = [ \"identify\", \"config\", \"track\", \"reset\", \"debug\", \"show\", \"ping\", \"page\", \"hide\", \"off\", \"on\" ],\n      t.factory = function(e) {\n        return function() {\n          var n = Array.prototype.slice.call(arguments);\n          return n.unshift(e), t.push(n), t;\n        };\n      }, t.methods.forEach(function(e) {\n            t[e] = t.factory(e);\n      }), t.load = function(t) {\n        var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement(\"script\");\n        o.type = \"text/javascript\", o.async = !0, o.crossorigin = \"anonymous\", o.src = \"https://js.driftt.com/include/\" + n + \"/\" + t + \".js\";\n        var i = document.getElementsByTagName(\"script\")[0];\n        i.parentNode.insertBefore(o, i);\n      };\n    }\n  }();\n  drift.SNIPPET_VERSION = '0.3.1';\n  drift.load('2h896hyiupic');"
                  },
                  type_: "text/javascript",
                  strategy: "afterInteractive"
                }), React.createElement(Head, {
                  children: React.createElement("title", undefined, ReactUtils$SmartcutsMacros.s("Smartcuts"))
                }), React.createElement(Index$Hero, {}), tmp, React.createElement("section", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(40)),
                        tl: /* [] */0
                      })
                }, React.createElement("aside", {
                      className: Curry._1(Css.style, {
                            hd: Css.textAlign("center"),
                            tl: /* [] */0
                          })
                    }, React.createElement("h2", {
                          className: Curry._1(Css.style, {
                                hd: Css.color(Css.hex("1A8FE6")),
                                tl: {
                                  hd: Css.fontWeight("lighter"),
                                  tl: {
                                    hd: Css.fontSize({
                                          NAME: "px",
                                          VAL: 32
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, ReactUtils$SmartcutsMacros.s("Works with any website you want"))), React.createElement("ul", {
                      className: supportedAppsList
                    }, React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Google Sheets",
                              src: "/img/3plogos/sheets.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Gmail",
                              src: "/img/3plogos/gmail.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Slack",
                              src: "/img/3plogos/slack.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Google Calendar",
                              src: "/img/3plogos/gcal.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Trello",
                              src: "/img/3plogos/trello.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Facebook",
                              src: "/img/3plogos/facebook.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "LinkedIn",
                              src: "/img/3plogos/linkedin.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Asana",
                              src: "/img/3plogos/asana.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Facebook Messenger",
                              src: "/img/3plogos/messenger.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Amazon",
                              src: "/img/3plogos/amazon.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Salesforce",
                              src: "/img/3plogos/salesforce.jpg"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "LinkedIn",
                              src: "/img/3plogos/linkedin.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Shopify",
                              src: "/img/3plogos/shopify.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Twitter",
                              src: "/img/3plogos/twitter.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "Customer.io",
                              src: "/img/3plogos/customer.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "PayPal",
                              src: "/img/3plogos/paypal.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("img", {
                              alt: "ZenDesk",
                              src: "/img/3plogos/zendesk.png"
                            })), React.createElement("li", {
                          className: supportedApp
                        }, React.createElement("svg", {
                              height: "32",
                              width: "30",
                              fill: "rgb(255, 56, 92)"
                            }, React.createElement("path", {
                                  d: "M29.24 22.68c-.16-.39-.31-.8-.47-1.15l-.74-1.67-.03-.03c-2.2-4.8-4.55-9.68-7.04-14.48l-.1-.2c-.25-.47-.5-.99-.76-1.47-.32-.57-.63-1.18-1.14-1.76a5.3 5.3 0 00-8.2 0c-.47.58-.82 1.19-1.14 1.76-.25.52-.5 1.03-.76 1.5l-.1.2c-2.45 4.8-4.84 9.68-7.04 14.48l-.06.06c-.22.52-.48 1.06-.73 1.64-.16.35-.32.73-.48 1.15a6.8 6.8 0 007.2 9.23 8.38 8.38 0 003.18-1.1c1.3-.73 2.55-1.79 3.95-3.32 1.4 1.53 2.68 2.59 3.95 3.33A8.38 8.38 0 0022.75 32a6.79 6.79 0 006.75-5.83 5.94 5.94 0 00-.26-3.5zm-14.36 1.66c-1.72-2.2-2.84-4.22-3.22-5.95a5.2 5.2 0 01-.1-1.96c.07-.51.26-.96.52-1.34.6-.87 1.65-1.41 2.8-1.41a3.3 3.3 0 012.8 1.4c.26.4.45.84.51 1.35.1.58.06 1.25-.1 1.96-.38 1.7-1.5 3.74-3.21 5.95zm12.74 1.48a4.76 4.76 0 01-2.9 3.75c-.76.32-1.6.41-2.42.32-.8-.1-1.6-.36-2.42-.84a15.64 15.64 0 01-3.63-3.1c2.1-2.6 3.37-4.97 3.85-7.08.23-1 .26-1.9.16-2.73a5.53 5.53 0 00-.86-2.2 5.36 5.36 0 00-4.49-2.28c-1.85 0-3.5.86-4.5 2.27a5.18 5.18 0 00-.85 2.21c-.13.84-.1 1.77.16 2.73.48 2.11 1.78 4.51 3.85 7.1a14.33 14.33 0 01-3.63 3.12c-.83.48-1.62.73-2.42.83a4.76 4.76 0 01-5.32-4.07c-.1-.8-.03-1.6.29-2.5.1-.32.25-.64.41-1.02.22-.52.48-1.06.73-1.6l.04-.07c2.16-4.77 4.52-9.64 6.97-14.41l.1-.2c.25-.48.5-.99.76-1.47.26-.51.54-1 .9-1.4a3.32 3.32 0 015.09 0c.35.4.64.89.9 1.4.25.48.5 1 .76 1.47l.1.2c2.44 4.77 4.8 9.64 7 14.41l.03.03c.26.52.48 1.1.73 1.6.16.39.32.7.42 1.03.19.9.29 1.7.19 2.5z"
                                }))), React.createElement("li", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("inlineBlock"),
                                tl: {
                                  hd: Css.height(Css.px(50)),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("flexEnd"),
                                      tl: {
                                        hd: Css.justifyContent("center"),
                                        tl: {
                                          hd: Css.padding(Css.px(2)),
                                          tl: {
                                            hd: Css.margin(Css.px(5)),
                                            tl: {
                                              hd: Css.color(Css.rgb(153, 153, 153)),
                                              tl: {
                                                hd: Css.fontSize(Css.px(17)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, ReactUtils$SmartcutsMacros.s("and more ...")))));
}

var $$default = Index$default;

exports.Style = Style;
exports.SignupForm = SignupForm;
exports.Feature = Feature;
exports.Hero = Hero;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* buttonAction Not a pure module */
