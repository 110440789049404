// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Head = require("next/head").default;
var Header$SmartcutsMacros = require("./Header.bs.js");
var ReactUtils$SmartcutsMacros = require("../../packages/bs-utils/src/ReactUtils.bs.js");

function LayoutPublic(Props) {
  var homeOpt = Props.home;
  var onClickCallToAction = Props.onClickCallToAction;
  var children = Props.children;
  var home = homeOpt !== undefined ? homeOpt : false;
  return React.createElement(React.Fragment, undefined, React.createElement(Head, {
                  children: null
                }, React.createElement("link", {
                      href: "/favicon-16.png",
                      rel: "icon",
                      sizes: "16x16",
                      type: "image/png"
                    }), React.createElement("link", {
                      href: "/favicon-32.png",
                      rel: "icon",
                      sizes: "32x32",
                      type: "image/png"
                    }), React.createElement("link", {
                      href: "/favicon-96.png",
                      rel: "icon",
                      sizes: "96x96",
                      type: "image/png"
                    })), React.createElement(Header$SmartcutsMacros.make, {
                  home: home,
                  onClickCallToAction: onClickCallToAction
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight({
                                  NAME: "vh",
                                  VAL: 80.0
                                }),
                            tl: {
                              hd: Css.fontFamily({
                                    NAME: "custom",
                                    VAL: "\"Helvetica Neue\", Helvetica, Arial, sans-serif"
                                  }),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, children), React.createElement("footer", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceAround"),
                            tl: {
                              hd: Css.flexWrap("wrap"),
                              tl: {
                                hd: Css.fontFamily({
                                      NAME: "custom",
                                      VAL: "\"Helvetica Neue\", Helvetica, Arial, sans-serif"
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.color(Css.hex("acacae")),
                            tl: {
                              hd: Css.fontSize({
                                    NAME: "px",
                                    VAL: 12
                                  }),
                              tl: {
                                hd: Css.selector("& > a", {
                                      hd: Css.color(Css.hex("acacae")),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, ReactUtils$SmartcutsMacros.s("2021 © Smartcuts"), ReactUtils$SmartcutsMacros.s(" | "), React.createElement("a", {
                          href: "/privacy_policy.html",
                          target: "_blank"
                        }, ReactUtils$SmartcutsMacros.s("Privacy policy")), ReactUtils$SmartcutsMacros.s(" | "), React.createElement("a", {
                          href: "/terms_of_service.html",
                          target: "_blank"
                        }, ReactUtils$SmartcutsMacros.s("Terms of service")), ReactUtils$SmartcutsMacros.s(" | "), React.createElement("a", {
                          href: "/cookie_policy.html",
                          target: "_blank"
                        }, ReactUtils$SmartcutsMacros.s("Cookie policy")))));
}

var make = LayoutPublic;

exports.make = make;
/* Css Not a pure module */
