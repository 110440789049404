// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");

function YouTubeEmbed(Props) {
  var id = Props.id;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var autoplayOpt = Props.autoplay;
  var playsinlineOpt = Props.playsinline;
  var width = widthOpt !== undefined ? widthOpt : 560;
  var height = heightOpt !== undefined ? heightOpt : 315;
  var autoplay = autoplayOpt !== undefined ? autoplayOpt : false;
  var playsinline = playsinlineOpt !== undefined ? playsinlineOpt : false;
  var element = React.createElement("iframe", {
        height: String(height),
        src: "https://www.youtube.com/embed/" + (id + ("?rel=0&fs=1&autoplay=" + ((
                autoplay ? "1" : "0"
              ) + ("&playsinline=" + (
                  playsinline ? "1" : "0"
                ))))),
        width: String(width)
      });
  return React.cloneElement(element, Js_dict.fromList({
                  hd: [
                    "frameBorder",
                    "0"
                  ],
                  tl: {
                    hd: [
                      "allow",
                      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    ],
                    tl: {
                      hd: [
                        "allowFullScreen",
                        ""
                      ],
                      tl: /* [] */0
                    }
                  }
                }));
}

var make = YouTubeEmbed;

exports.make = make;
/* react Not a pure module */
